.table-root {
	flex-grow: 0;
	z-index: 0;
	width: 100%;
	overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}
}

/*
.table-container {
	//height: 100%;
	top: 0;
	//height: calc(100vh - 64px);
	//height: 100vh;
	height: calc(100vh - 250px);
	//height: 200px;
	//overflow-y: auto;
}
*/
.table {
	.teamName {
		display: inline-block;
    width: 250px;
	}
	//border: 1px solid #ddd;
	border-collapse: collapse;
	.tr:last-child {
		.td {
			border-bottom: 0;
		}
	}
	/*	.tr:nth-of-type(odd) {
		.td {
			background-color: #c7c5c5;
			//background-color: red;
		}
	}*/
	.trFluo {
		.td {
			background-color: #e6d024;
		}
	}
	span {
		//display: inline-block;
		//width: 150px;
		//border: 1px solid green;
		//padding: 2px;
		//vertical-align: bottom;
		img {
			vertical-align: top;
			//border: 1px solid red;
		}
	}

	.th {
		//z-index: 99;
		//background-color: #424242;
	}
	.th,
	.td {
		padding: 2px;
		font-size: small;
		//vertical-align: baseline;
		/*	border-bottom: 1px solid #ddd;
		border-right: 1px solid #ddd;
    background-color: #fff;*/
		overflow: hidden;

		:last-child {
			border-right: 0;
		}
	}

	&.sticky {
		overflow: scroll;
		.header,
		.footer {
			position: sticky;
			//z-index: 99;
			width: fit-content;
		}

		.header {
			top: 0;
			//	box-shadow: 0px 3px 3px #ccc;
		}

		.footer {
			bottom: 0;
			//		box-shadow: 0px -3px 3px #ccc;
		}

		.body {
			position: relative;
			z-index: 0;
		}

		[data-sticky-td] {
			position: sticky;
			z-index: 98;
		}

		.header,
		.trh {
			position: sticky;
			top: 0;
			z-index: 99;
		}
		.th {
			//z-index: 99;
		}
		[data-sticky-last-left-td] {
			//box-shadow: 2px 0px 3px #ccc;
			z-index: 98;
		}

		[data-sticky-first-right-td] {
			//box-shadow: -2px 0px 3px #ccc;
		}
	}
}
